import React from "react";
import {NavLink} from "react-router-dom";
import warehouse from "../../../assets/img/warehouse.svg";

const WarehousesBlock = (props) => {
    return (
        <>
            <NavLink to={props.link + props.data.guid} className="itemBlockLoacation">
                <div className="flexBlock">
                            <span className="warehouse_icon">
                                <img src={warehouse} alt=""/>
                            </span>
                    <span className="warehouse_desc">{props.data.name}</span>
                </div>
            </NavLink>
        </>
    )
}

export default WarehousesBlock;
