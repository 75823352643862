import React from "react";
import {NavLink} from "react-router-dom";
import send from "../../../assets/img/md-send.svg";
import home from "../../../assets/img/ios-home.svg";

const ObjectBlockNew = (props) => {

    const checkItem = (docData) => {
        // Проверим, есть ли принятые частично или полностью позиции?
        let allMaterialSupply = true
        docData.order.map(material => {
            // Если хотя бы одна из позиций не принята или принята частично
            if (Number(material.quantity) !== Number(material.quantitySupply) && allMaterialSupply) {
                allMaterialSupply = false
            }
        })

        return allMaterialSupply
    }

    const notCompleteDelivery = (docData) => {
        // Проверим, есть ли принятые частично позиции?
        let allMaterialSupply = true
        docData.order.map(material => {
            // Если хотя бы одна из позиций не принята или принята частично
            if (Number(material.quantity) !== Number(material.quantitySupply) && Number(material.quantitySupply) > 0 && allMaterialSupply) {
                allMaterialSupply = false
            }
        })

        return allMaterialSupply
    }

    let allMaterialSupplyClass = ''

    if (!notCompleteDelivery(props.data)) {
        allMaterialSupplyClass = 'allMaterialSupplyClass'
    }

    let classIndicatorSends = '';

    if (props.data.send) {
        classIndicatorSends = ' sendActive ';
    }

    if (!checkItem(props.data)) { // отобразим, только непроведение документы
        return (
            <NavLink to={props.link + props.filter + '/document/' + props.data.id}
                     className={"itemBlockObject " + allMaterialSupplyClass + classIndicatorSends}>
                <p>
                    <span>
                        {props.data.send &&
                            <img src={send} alt=""/>
                        }
                        {!props.data.send &&
                            <img src={home} alt=""/>
                        }
                    </span>
                    Объект №{props.data.object} ({props.data.number}) <br/>
                    <smal>От {props.data.sender}</smal>
                            {props.data.send && ' отправка...'}
                </p>
                <small>{props.data.doc_info.receiptDate.split(' ')[0]}</small>
            </NavLink>
        )
    }
}
export default ObjectBlockNew;
