export const IndexedDB = () => {
    return new Promise((resolve, reject) => {

        // объект соединения с базой данных
        let openRequest = null

        // обработка ошибки если браузер не поддерживает indexedDb
        if (!('indexedDB' in window)) reject('not supported');

        // открывает базу данных
        openRequest = indexedDB.open("store", 1);

        openRequest.onupgradeneeded = function () {

            let db = openRequest.result;

            if (!db.objectStoreNames.contains('documents')) { // если хранилище "documents" не существует
                db.createObjectStore('documents', {autoIncrement: true}); // создаём хранилище
            }

            if (!db.objectStoreNames.contains('warehouses')) { // если хранилище "documents" не существует
                db.createObjectStore('warehouses', {autoIncrement: true}); // создаём хранилище
            }

            if (!db.objectStoreNames.contains('online_sends')) { // если хранилище "online_sends" не существует
                db.createObjectStore('online_sends', {autoIncrement: true}); // создаём хранилище
            }

            if (!db.objectStoreNames.contains('warehouses_sends')) { // если хранилище "warehouses_sends" не существует
                db.createObjectStore('warehouses_sends', {autoIncrement: true}); // создаём хранилище
            }

            if (!db.objectStoreNames.contains('user')) { // если хранилище "sends" не существует
                db.createObjectStore('user', {autoIncrement: true}); // создаём хранилище
            }
        };

        openRequest.onsuccess = function () {
            let db = openRequest.result;
            // продолжить работу с базой данных, используя объект db
            resolve(db);

        };

        openRequest.onerror = function (e) {
            reject(`IndexedDB error: ${e.target.errorCode}`);
        };
    });
}
