const defaultState = {
    authorization: {
        id: false,
        name: false,
        state: false,
        token: null
    }
}
// Добавление состояния объекта анимации отправки данных в store
const SET_AUTH = "SET_AUTH"
// Удаление состояния объекта анимации отправки данных из store
const REMOVE_AUTH = "REMOVE_AUTH"

export const authorizationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return state.authorization = {
                ...state, authorization: {
                    id: action.payload.id,
                    name: action.payload.name,
                    state: true,
                    token: action.payload.token
                }
            }
        case REMOVE_AUTH:
            return state.authorization = {
                ...state, authorization: {
                    id: false,
                    name: false,
                    state: false,
                    token: null
                }
            }
        default:
            if (localStorage.getItem('token')) {
                return {
                    authorization: {
                        id: localStorage.getItem('id_user'),
                        name: localStorage.getItem('user'),
                        state: true,
                        token: localStorage.getItem('token')
                    }
                }
            } else {
                return {
                    authorization: {
                        id: false,
                        name: false,
                        state: false,
                        token: null
                    }
                }
            }

    }
}

export const setAuthorizationAction = (payload) => ({type: SET_AUTH, payload})
export const removeAuthorizationAction = (payload) => ({type: REMOVE_AUTH, payload})
