import React, {useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetFilterAction, setFilterAction} from "../../../store/filterReducer";
import menu from '../../../assets/img/menu.svg';
import {NavLink} from "react-router-dom";

const HeaderW = (props) => {

    const dispatch = useDispatch()

    const filterStore = useSelector(state => state.filter.filter);

    const filterActive = () => {
        dispatch(setFilterAction())
    }

    useLayoutEffect(() => {
        dispatch(resetFilterAction())
    }, [])

    return (<>
            <div className="header">
                {props.filter &&
                    <div className="filterIcon" onClick={() => filterActive()}>
                        {props.filter &&
                            <img src={menu} alt=""/>
                        }
                    </div>
                }
                <div className="titleHeader">
                    {!filterStore.active &&
                        <p>{props.title}</p>
                    }
                    {filterStore.active &&
                        <p>Меню</p>
                    }
                </div>
            </div>
            {(filterStore.active && props.filter) &&
                <div className="filter">
                    <div className="close" onClick={() => filterActive()}>
                        <ion-icon name="close-circle-outline"></ion-icon>
                    </div>
                    <div className="screenBtnBlock">
                        <NavLink to="/loc">
                            <div className="BtnScreen">Перемещения</div>
                        </NavLink>
                        <NavLink to="/warehouses">
                            <div className="BtnScreen">Склады</div>
                        </NavLink>
                        <div className="hrBlock">
                            <NavLink to="/info">
                                <div className="BtnScreen">Информация</div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default HeaderW;
