import {IndexedDB} from "./indexeddb";
import {updateDocumentAction} from "../store/documentReducer";

export const deSendReq = () => {
    return function (dispach) {
        IndexedDB().then(db => {
            // Запустим транзакцию базы данных и получите хранилище объектов documents
            let tx = db.transaction(['online_sends'], 'readonly');
            let store = tx.objectStore('online_sends');

            // Создать запрос курсора
            let req = store.openCursor();
            let allDocument = [];

            req.onsuccess = (event) => {
                let cursor = event.target.result;
                if (cursor != null) {
                    allDocument.push(cursor.value);
                    cursor.continue();
                } else {
                    allDocument.map(
                        document => {
                            // запустить обновление статусов отправленных документов
                            dispach(updateDocumentAction({
                                section: 'send',
                                data: true,
                                guid: document.id,
                                document: document
                            }))
                        }
                    )
                }
            }

            // Ожидаем завершения транзакции базы данных
            tx.oncomplete = () => {
               //
            }
            tx.onerror = (event) => {
                alert('Ошибка записи документов ' + event.target.errorCode);
            }
        })
    }
}
