const defaultState = {
    list: []
}
// Добавление позиции склада
const ADD_WAREHOUSES = "ADD_WAREHOUSES"
// Обновление склада
//const UPDATE_WAREHOUSES = "UPDATE_WAREHOUSES"
// Сброс всех складов
const REMOVE_WAREHOUSES = "REMOVE_WAREHOUSES"

export const warehousesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_WAREHOUSES:
            state.list.push(action.payload);
            return state;
        case REMOVE_WAREHOUSES:
            return {...state, list: []}
        default:
            return state
    }
}

export const addWarehousesAction = (payload) => ({type: ADD_WAREHOUSES, payload})
//export const updateWarehousesAction = (payload) => ({type: UPDATE_WAREHOUSES, payload})
export const removeWarehousesAction = (payload) => ({type: REMOVE_WAREHOUSES, payload})
