import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {store, persistor} from "./store";
import './assets/fonts/Roboto/stylesheet.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style.min.css';
import {PersistGate} from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

serviceWorkerRegistration.register();

reportWebVitals();
