import React from "react";
import {NavLink} from "react-router-dom";
import today from "./../../../assets/img/md-today.svg";
import timer from "./../../../assets/img/md-timer.svg";
import archive from "./../../../assets/img/ios-archive.svg";
import information from "./../../../assets/img/md-information-circle-outline.svg";

const MenuW = (props) => {

    let color_nav_1 = ''
    let color_nav_2 = ''
    let color_nav_3 = ''
    let color_nav_4 = ''

    switch (props.screen) {
        case '1': color_nav_1 = "is_active"; break;
        case '2': color_nav_2 = "is_active"; break;
        case '3': color_nav_3 = "is_active"; break;
        case '4': color_nav_4 = "is_active"; break;
        default:
            color_nav_1 = 'is_active';
            color_nav_2 = '';
            color_nav_3 = '';
            color_nav_4 = '';
    }

    return (
        <nav>
            <NavLink className={color_nav_1} to="/loc">
                <img src={today} alt=""/>
            </NavLink>
            <NavLink className={color_nav_2} to="/plan">
                <img src={timer} alt=""/>
            </NavLink>
            <NavLink className={color_nav_3} to="/archive">
                <img src={archive} alt=""/>
            </NavLink>
            <NavLink className={color_nav_4} to="/info">
                <img src={information} alt=""/>
            </NavLink>
        </nav>
    )
}

export default MenuW;
