import React from "react";
import HeaderW from "../../widgets/header/HeaderW";
import {NavLink} from "react-router-dom";


const StartPage = () => {
    return (
        <>
            <HeaderW filter={true} title="Мобильный прораб"/>
            <div className="screenBlock">
                <div className="screenBtnBlock pageErrorMargin">
                    <NavLink to="/loc">
                        <div className="BtnScreen">Перемещения</div>
                    </NavLink>
                    <NavLink to="/warehouses">
                        <div className="BtnScreen">Склады</div>
                    </NavLink>
                    <div className="hrBlockStart">
                        <NavLink to="/info">
                            <div className="BtnScreen">Информация</div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StartPage;
