import React, {useEffect, useLayoutEffect, useState} from "react";
import AccordionBlock from "./AccordionBlock";

const BlockContent = (props) => {
    const [dataBlock, setDataBlock] = useState([]);
    const [contentBlock, setContentBlock] = useState([]);

    useLayoutEffect(() => {
        setDataBlock(props.data);
    }, [props]);
    useEffect(() => {

        let Block = [];

        for (let blockItem in dataBlock) {
            Block.push(blockItem);
        }

        setContentBlock(Block)
    }, [dataBlock]);


    return (
        <>
            {contentBlock && contentBlock.map((block, index) =>
                <AccordionBlock key={index} block={block} data={dataBlock} />
                )
            }
        </>
    )

}

export default BlockContent;
