import {applyMiddleware, combineReducers, createStore} from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {thunk} from "redux-thunk";
import {composeWithDevTools} from "@redux-devtools/extension";
import {authorizationReducer} from "./authorizationReducer";
import {documentReducer} from "./documentReducer";
import {screenReducer} from "./screenReducer";
import {filterReducer} from "./filterReducer";
import {refreshReducer} from "./refreshReducer";
import {takenToWorkReducer} from "./takenToWorkReducer";
import {workListReducer} from "./workListReducer";
import {senderReducer} from "./senderReducer";
import {warehousesReducer} from "./warehousesReducer";
import {editWarehousesReducer} from "./editWarehousesReducer";
import {selectReducer} from "./selectReducer";
import {openBlockReducer} from "./openBlockReducer";

const rootReducer = combineReducers({
    authorizationReducer: authorizationReducer,
    document: documentReducer,
    screen: screenReducer,
    filter: filterReducer,
    refresh: refreshReducer,
    work: takenToWorkReducer,
    work_list: workListReducer,
    sender: senderReducer,
    warehouses: warehousesReducer,
    editWarehouses: editWarehousesReducer,
    selectPoint: selectReducer,
    openBlock: openBlockReducer,
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persistor = persistStore(store);

