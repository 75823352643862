import React, {useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetFilterAction, setFilterAction} from "../../../store/filterReducer";
import edit from '../../../assets/img/edit.svg';
import menu from '../../../assets/img/menu.svg';
import doubleDown from '../../../assets/img/chevron-double-down.svg';
import doubleUp from '../../../assets/img/chevron-double-up.svg';
import save from '../../../assets/img/ios-save.svg';
import {NavLink} from "react-router-dom";
import {setEditWarehousesAction} from "../../../store/editWarehousesReducer";
import {resetOpenAction, setOpenAction} from "../../../store/openBlockReducer";
const HeaderWar = (props) => {

    const dispatch = useDispatch()

    const filterStore = useSelector(state => state.filter.filter);
    const editWarehouses = useSelector(state => state.editWarehouses.status);
    const openStatus = useSelector(state => state.openBlock);

    const filterActive = () => {
        dispatch(setFilterAction())
    }

    const activeEdit = () => {
        dispatch(setEditWarehousesAction(editWarehouses))
    }

    const activeOpen = () => {
        if(openStatus) {
            dispatch(resetOpenAction())
        } else {
            dispatch(setOpenAction())
        }
    }

    useLayoutEffect(() => {
        dispatch(resetFilterAction())
    }, [])

    return (<>
            <div className="header">
                {props.filter &&
                    <div className="filterIcon" onClick={() => filterActive()}>
                        {props.filter &&
                            <img src={menu} alt=""/>
                        }
                    </div>
                }
                <div className="titleHeader">
                    {!filterStore.active &&
                        <p>{props.title}</p>
                    }
                    {filterStore.active &&
                        <><p>Меню</p></>
                    }
                </div>

                <div className="editBox" onClick={() => activeOpen()}>
                    {!openStatus &&
                        <img src={doubleDown} alt="Открыть все карточки"/>
                    }
                    {openStatus &&
                        <img src={doubleUp} alt="Закрыть все карточки"/>
                    }
                </div>

                <div className="editBox" onClick={() => activeEdit()}>
                    {!editWarehouses &&
                        <img src={edit} alt="Режим редактирования"/>
                    }
                    {editWarehouses &&
                        <img src={save} alt="Режим редактирования"/>
                    }
                </div>
            </div>
            {(filterStore.active && props.filter) &&
                <div className="filter">
                    <div className="close" onClick={() => filterActive()}>
                        <ion-icon name="close-circle-outline"></ion-icon>
                    </div>
                    <div className="screenBtnBlock">
                    <NavLink to="/loc">
                            <div className="BtnScreen">Перемещения</div>
                        </NavLink>
                        <NavLink to="/warehouses">
                            <div className="BtnScreen">Склады</div>
                        </NavLink>
                        <div className="hrBlock">
                            <NavLink to="/info">
                                <div className="BtnScreen">Информация</div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default HeaderWar;
