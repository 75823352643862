import React, {useEffect, useState} from "react";
import HeaderW from "../../widgets/header/HeaderW";
import MenuW from "../../widgets/menu/MenuW";
import {useDispatch, useSelector} from "react-redux";
import LocationBlock from "../../widgets/content/LocationBlock";
import FooterW from "../../widgets/footer/FooterW";
import no_screen_data from "../../../assets/img/no_screen_data.svg";
import {deSendReq} from "../../../asinc/deSendReq";

const PlanPage = () => {
    /** Компонент для отображения всех локаций, на территории которых планируется привоз материалов */
    const dispatch = useDispatch();
    const documentStore = useSelector(state => state.document.documents);
    const [documentScreen, setDocumentScreen] = useState([]);

    const selectLocation = (documents) => {
        /** Отфильтровать уникальные локации, за указанный период */
            // Получаем текущую дату и обнуляем время
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        // Создаем дату для следующего дня
        let nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 3);
        let outList = [];
        documents.forEach(docState => {
            // Парсим дату из объекта и обнуляем время
            let objectDate = new Date(docState.date);
            objectDate.setHours(0, 0, 0, 0);
            // Проверяем, попадает ли дата объекта в допустимый интервал и его статус проведения
            if (objectDate > nextDay) {
                outList.push(docState);
            }
        });

        let resArr = [];

        outList.filter(function (item) {
            let i = resArr.findIndex(x => (x.location === item.location));
            if (i <= -1) {
                resArr.push(item);
            }
            return null;
        });

        setDocumentScreen(resArr);

        return resArr;
    }

    useEffect(() => {
        setTimeout(function () {
            dispatch(deSendReq());
            selectLocation(documentStore);
        }, 100);
    }, [documentStore, dispatch])

    if (documentScreen !== undefined && documentScreen.length === 0) {
        return (<>
                <HeaderW filter={true} title="Планируемые"/>
                <div className="screenBlock">
                    <p><br/></p>
                    <p><br/></p>
                    <p className="downText">Нет данных для отображения</p>
                    <img src={no_screen_data} alt="" className="downIco"/>
                </div>
                <FooterW/>
                <MenuW screen="2"/>
            </>
        )
    } else {
        return (<>
                <HeaderW filter={true} title="Планируемые"/>
                <div className="screenBlock">
                    {documentScreen.map((document, key) =>
                        <LocationBlock link="/plan/loc/" data={document} key={key}/>
                    )}
                    <div className="bottom_space_list"></div>
                </div>
                <FooterW/>
                <MenuW screen="2"/>
            </>
        )
    }

}

export default PlanPage;
