const defaultState = {
    status: false
}
// Изменить режим редактирования склада
const SET_EDIT_WAREHOUSES = "SET_EDIT_WAREHOUSES"

export const editWarehousesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_EDIT_WAREHOUSES:
            return {...state, status: !action.payload};
        default:
            return state
    }
}

export const setEditWarehousesAction = (payload) => ({type: SET_EDIT_WAREHOUSES, payload})
