
import React from "react";
// Компонент PrivateRoute используется для защиты определенных маршрутов в приложении.
import {Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import AuthorizationPage from "./AuthorizationPage";

const PrivateRoute = () => {
    const authorizationStore = useSelector(state => state.authorizationReducer.authorization);

    if(!authorizationStore.state) {
        return(<AuthorizationPage />)
    } else {
        return (<Outlet/>)
    }
}

export default PrivateRoute;
