import React, {useEffect, useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import HeaderW from "../../widgets/header/HeaderW";
import MenuW from "../../widgets/menu/MenuW";
import FooterW from "../../widgets/footer/FooterW";
import PullToRefresh from 'react-pull-to-refresh';
import {fetchGet1CData} from "../../../asinc/get1cData";
import {resetRefreshAction, setRefreshAction} from "../../../store/refreshReducer";
import LocationBlock from "../../widgets/content/LocationBlock";
import {addDocumentAction, clearDocumentAction} from "../../../store/documentReducer";
import down from '../../../assets/img/down.svg';
import {deSendReq} from "../../../asinc/deSendReq";
import lodaing from '../../../assets/img/update_data.gif';


/** Компонент для отображения всех локаций, на территории которых планируется привоз материалов */
const CurrentPage = () => {

    const dispatch = useDispatch();
    const authorizationStore = useSelector(state => state.authorizationReducer.authorization);
    const documentStore = useSelector(state => state.document.documents);
    const refreshStore = useSelector(state => state.refresh.state);
    const [updateServerIcon, setUpdateServerIcon] = useState(false);
    const [documentScreen, setDocumentScreen] = useState([]);
    const [updateScreen, setUpdateScreen] = useState(false);

    const online = navigator.onLine;

    const selectLocation = (documents) => {
        /** Отфильтровать уникальные локации, за указанный период */
            // Получаем текущую дату и обнуляем время
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        // Создаем дату для следующего дня
        let nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 3);
        let outList = []
        documents.forEach(docState => {
            // Парсим дату из объекта и обнуляем время
            let objectDate = new Date(docState.date);
            objectDate.setHours(0, 0, 0, 0);
            // Проверяем, попадает ли дата объекта в допустимый интервал и его статус проведения
            if (objectDate <= nextDay && !docState.final) {
                outList.push(docState)
            }
        })

        let resArr = [];

        outList.filter(function (item) {
            let i = resArr.findIndex(x => (x.location === item.location));
            if (i <= -1) {
                resArr.push(item);
            }
            return null;
        });

        setDocumentScreen(resArr);

        return resArr;
    }

// Ваша функция handleRefresh с использованием useCallback
    const handleRefresh = useCallback(() => {
        setUpdateServerIcon(true);
        // Получаем текущую дату
        let currentDate = new Date();
        // Получаем первый день текущего месяца
        let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        // Получаем последний день текущего месяца
        let lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        // Функция для форматирования даты в формат "год-месяц-день"
        function formatDate(date) {
            let year = date.getFullYear();
            let month = ('0' + (date.getMonth() + 1)).slice(-2); // добавляем ноль, если месяц < 10
            let day = ('0' + date.getDate()).slice(-2); // добавляем ноль, если день < 10
            return year + '-' + month + '-' + day;
        }

        // Преобразуем даты в формат "год-месяц-день"
        let formattedFirstDayOfMonth = formatDate(firstDayOfMonth);
        let formattedLastDayOfMonth = formatDate(lastDayOfMonth);

        dispatch(setRefreshAction());

        if (navigator.onLine) {
            fetchGet1CData({
                'startData': formattedFirstDayOfMonth,
                'endData': formattedLastDayOfMonth,
                'token': authorizationStore.token,
                'test': false
            }).then(res => {

                dispatch(clearDocumentAction());

                res.forEach(doc => {
                    dispatch(addDocumentAction(doc))
                });
                dispatch(resetRefreshAction());
                setUpdateScreen(true);
                setUpdateServerIcon(false);
                dispatch(deSendReq());
            }).catch(error => dispatch(resetRefreshAction()));
        } else {
            //console.info('Нет доступа к серверу')
            dispatch(resetRefreshAction())
            setUpdateScreen(true);
            setUpdateServerIcon(false);
        }
    }, [authorizationStore.token, dispatch]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    useEffect(() => {
        setTimeout(function () {
            selectLocation(documentStore);
        }, 100);
    }, [documentStore, dispatch])

    useEffect(() => {
        dispatch(deSendReq());
        selectLocation(documentStore);
    }, [updateScreen, dispatch, documentStore])

    if (documentScreen !== undefined && documentScreen.length === 0) {
        return (<>
                <PullToRefresh onRefresh={handleRefresh} refreshing={refreshStore.toString()}>
                    <div className={`refresh-overlay ${refreshStore ? 'show' : ''}`}>
                        <div className="loader"></div>
                        <div>{!online && "Нет интернета!"}{online && "Загрузка данных..."}</div>
                    </div>
                    <HeaderW filter={true} title="Текущие"/>
                    <div className="screenBlock">
                        <p><br/></p>
                        <p><br/></p>
                        <p className="downText">
                            Нет документов для отображения<br/>
                            Потяните вниз экран,<br/>
                            для загрузки и обновления данных</p>
                        <img src={down} alt="" className="downIco"/>
                    </div>
                </PullToRefresh>
                <FooterW/>
                {updateServerIcon &&
                    <div className="updateScreenBlock">
                        <img src={lodaing} alt="Обновление данных с сервера" className="updateIco"/>
                    </div>
                }
                <MenuW screen="1"/>
            </>
        )
    } else {
        return (<>
                <PullToRefresh onRefresh={handleRefresh} refreshing={refreshStore.toString()}>
                    <div className={`refresh-overlay ${refreshStore ? 'show' : ''}`}>
                        <div className="loader"></div>
                        <div>{!online && "Нет интернета!"}{online && "Загрузка данных..."}</div>
                    </div>
                    <HeaderW filter={true} title="Текущие"/>
                    <div className="hr_line"></div>
                    <div className="screenBlock">
                        {documentScreen.map((document, key) =>
                            <LocationBlock link="/loc/" data={document} key={key}/>
                        )}
                        <div className="bottom_space_list"></div>
                    </div>
                </PullToRefresh>
                {updateServerIcon &&
                    <div className="updateScreenBlock">
                        <img src={lodaing} alt="Обновление данных с сервера" className="updateIco"/>
                    </div>
                }
                <FooterW/>
                <MenuW screen="1"/>
            </>
        )
    }
}

export default CurrentPage;
