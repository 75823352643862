const defaultState = {
    state: false
}
// Добавление локации в store
const ACTIVE_REFRESH = "ACTIVE_REFRESH"
// Удаление локации из store
const RESET_REFRESH = "RESET_REFRESH"

export const refreshReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ACTIVE_REFRESH:
            return {...state, state: true}
        case RESET_REFRESH:
            return {...state, state: false}
        default:
            return state
    }
}

export const setRefreshAction = (payload) => ({type: ACTIVE_REFRESH, payload})
export const resetRefreshAction = (payload) => ({type: RESET_REFRESH, payload})