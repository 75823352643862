import React, {useState} from "react";
import HeaderW from "../../widgets/header/HeaderW";
import MenuW from "../../widgets/menu/MenuW";
import FooterW from "../../widgets/footer/FooterW";
import {useDispatch, useSelector} from "react-redux";
import {removeAuthorizationAction} from "../../../store/authorizationReducer";
import {removeTakenToWorkAction} from "../../../store/takenToWorkReducer";
import {resetFilterAction} from "../../../store/filterReducer";
import {clearDocumentAction} from "../../../store/documentReducer";
import AuthorizationPage from "../authorization/AuthorizationPage";
import {send_log_data} from "../../../asinc/send_log_data";

const InfoPage = () => {

    const dispatch = useDispatch();

    const [connect, setConnect] = useState('');
    const [connectText, setConnectText] = useState(false);
    const [exit, setExit] = useState(false);

    const exitApp = () => {

        let userId = localStorage.getItem('id_user');
        let userName = localStorage.getItem('user');

        // Логируем выход из приложения
        send_log_data({
            uid: userId,
            uname: userName,
            action: 'Деавторизация пользователя: ' + userName,
            request: JSON.stringify({
                action: 'exit'
            })
        })

        // Удалим все данные из хранилищь
        dispatch(removeAuthorizationAction());
        dispatch(removeTakenToWorkAction());
        dispatch(resetFilterAction());
        dispatch(clearDocumentAction());

        localStorage.removeItem('token');
        localStorage.removeItem('timer');
        localStorage.removeItem('user');
        localStorage.removeItem('id_user');

        if (!('indexedDB' in window)) {
            console.error('indexedDB not supported!');
        } else {
            // Удалим локальную базу данных
            let deleteRequest = indexedDB.deleteDatabase('store');

            deleteRequest.onerror = function() {
                console.error("Error", deleteRequest.error);
            };

            deleteRequest.onsuccess = function() {
                // База данных успешно удалена
                console.info('База данных успешно удалена');
            };
        }

        if ('serviceWorker' in navigator) {
            // Получаем регистрацию сервисного работника
            navigator.serviceWorker.getRegistration().then(registration => {
                if (registration) {
                    // Отменяем регистрацию сервисного работника
                    registration.unregister().then(success => {
                        if (success) {
                            console.log('Service worker successfully unregistered');
                        } else {
                            console.error('Failed to unregister service worker');
                        }
                    });
                } else {
                    console.log('No service worker registered');
                }
            }).catch(error => {
                console.error('Error while unregistering service worker:', error);
            });
        }

        setExit(true);
    }

    // Делаем запрос к серверу 1С, что бы убедиться что связь есть
    const getPing1C = () => {
        // Отправим запрос на сервер 1С
        const status_url = "https://dev.domovedov.com/api/status.php";

        fetch(status_url, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({sinc: true}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(res => {
            setConnect('Есть связь с 1С')
            setConnectText(true)
        }).catch(error => {
            setConnect('Нет связи с 1С!')
            setConnectText(true)
        })
    }

    if(exit) {
        return(<AuthorizationPage />)
    } else {
        return (
            <>
                <HeaderW filter={true} title="Информация"/>
                <div className="screenInfoBlock">
                    <p><b>Пользователь:</b> {localStorage.getItem('user')}</p>
                    <p><br/></p>
                    <p><br/></p>
                    {connectText &&
                        <p className="testConnectRes">{connect}</p>
                    }
                    <p>
                        <div onClick={() => getPing1C()} className="btn btn-primary exitBtn">Тест связи с 1С</div>
                    </p>
                    <p><br/></p>
                    <p><br/></p>
                    <p>
                        <div onClick={() => exitApp()} className="btn btn-primary exitBtn">Выйти</div>
                    </p>
                </div>
                <FooterW/>
                <MenuW screen="4"/>
            </>
        )
    }


}

export default InfoPage;
