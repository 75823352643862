import React from "react";
import HeaderW from "../../widgets/header/HeaderW";
const NotFound = () => {
    return (
        <>
            <HeaderW filter={false} title="Страница не найдена"/>
        </>
    )
}

export default NotFound;