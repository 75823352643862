import {slugify} from "transliteration";

const defaultState = {
    documents: []
}
/** Мысль, создать в хранилище свой аналог реляционной БД */

/** 1. Правильное формирование записи, с учётом требуемых полей */

/** 3. Создание метода добавления, обновления и удаления документа по уникальному ID */

    // Обновление
const UPDATE_DOCUMENT = "UPDATE_DOCUMENT"
// Добавление документа
const ADD_DOCUMENT = "ADD_DOCUMENT"
// Удаление документа
const CLEAR_DOCUMENT = "CLEAR_DOCUMENT"

const finalCheck = (orders) => {
    let allMaterialSupply = true
    orders.map(material => {
        // Если хотя бы одна из позиций не принята или принята частично
        if(Number(material.quantity) !== Number(material.quantitySupply) && allMaterialSupply) {
            allMaterialSupply = false
        }
    })

    return allMaterialSupply
}

export const documentReducer = (state = defaultState, action) => {
    let newPropertyOrder = [];

    switch (action.type) {
        case ADD_DOCUMENT:
            // Необходим проверка на дубль документа в хранилище
            let writeFlag = true;
            state.documents.map(docItem => {
                if(docItem.number === action.payload.number) {
                    writeFlag = false;
                }
            })

            // Запишем документ, если его хэш не был найден среди документов уже записанных
            if(writeFlag) {
                let ArStrData = action.payload.receiptDate.substring(0, action.payload.receiptDate.indexOf(" 0:00:00")).split('.');
                const dataDocument = new Date(ArStrData[2] + '-' + ArStrData[1] + '-' + ArStrData[0]);
                // Добавим кастомные свойства
                action.payload.orderItems.map(orderItem => {
                    orderItem.total = orderItem.quantity;
                    newPropertyOrder.push(orderItem);
                });
                // Сформируем объект табличной записи
                let rowObject = {
                    id: action.payload.guid,
                    number: action.payload.number,
                    slug: slugify(action.payload.constructionObject.replace("Приобъектный склад ", "").split(',')[0]),
                    // - Локация
                    location: action.payload.constructionObject.replace("Приобъектный склад ", "").split(',')[0],
                    object: action.payload.constructionObject.replace("Приобъектный склад ", "").split(',')[1],
                    // - Статус проведения
                    final: finalCheck(action.payload.orderItems),
                    // - Статус отправки
                    send: false,
                    // - Форматированная дата
                    date: dataDocument,
                    // - Информация о документе
                    doc_info: {
                        guid: action.payload.guid,
                        number: action.payload.number,
                        receiptDate: action.payload.receiptDate,
                        constructionObject: action.payload.constructionObject,
                        analytic: action.payload.analytic,
                        sender: action.payload.sender
                    },
                    sender: action.payload.sender,
                    // - Массив данных о перемещении
                    order: newPropertyOrder
                }
                state.documents.push(rowObject)
            }
            return state;
        case CLEAR_DOCUMENT:
            return state = {documents: []};
        case UPDATE_DOCUMENT:
            let newState = [];
            state.documents.map(docState => {
                if (action.payload.guid === docState.id) {
                    switch (action.payload.section) {
                        case 'send_end':
                            docState.send = action.payload.data
                            newState.push(docState);
                            break;
                        case 'send':
                            if(action.payload.document) {
                                action.payload.document.orderItems.map(doc => {
                                    let materialStageUpdate = [];
                                    docState.order.map(materialStage => {
                                        if(materialStage.item.keyLine === doc.item.keyLine){
                                            // Если принимаемое количество меньше общего количества.
                                            if(materialStage.total <= (materialStage.quantitySupply +  doc.quantity)) {
                                                materialStage.quantitySupply = materialStage.total; // эмуляция ответа 1С
                                                materialStage.quantity = materialStage.total - (materialStage.quantitySupply +  doc.quantity);
                                            } else {
                                                materialStage.quantitySupply = (materialStage.quantitySupply +  doc.quantity); // эмуляция ответа 1С
                                                materialStage.quantity = materialStage.total;
                                            }
                                            if (materialStage.quantity  ) {

                                            }
                                            materialStageUpdate.push(materialStage);
                                        } else {
                                            materialStageUpdate.push(materialStage);
                                        }
                                    })
                                    docState.order = materialStageUpdate;
                                })
                                docState.send = action.payload.data;
                                newState.push(docState);
                            } else {
                                docState.send = action.payload.data;
                                newState.push(docState);
                            }
                            break;
                        case 'final':
                            docState.final = action.payload.data;
                            newState.push(docState);
                            break;
                        case 'order':
                            let orderArray = [];
                            docState.order.map(orderItem => {
                                if (orderItem.item.keyLine === action.payload.data.keyLine) {
                                    // Вносим изменение
                                    orderItem.quantity = Number(action.payload.data.num);
                                    orderArray.push(orderItem);
                                } else {
                                    orderArray.push(orderItem);
                                }
                            })
                            docState.order = orderArray;
                            newState.push(docState);
                            break;
                    }
                } else {
                    newState.push(docState);
                }
            })
            return {...state, documents: [...newState]};
        default:
            return state;
    }
}

export const addDocumentAction = (payload) => ({type: ADD_DOCUMENT, payload})
export const updateDocumentAction = (payload) => ({type: UPDATE_DOCUMENT, payload})
export const clearDocumentAction = (payload) => ({type: CLEAR_DOCUMENT, payload})
