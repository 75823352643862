const defaultState = {
    list: []
}
// Добавление пункта в хранилище
const ADD_POINT = "ADD_POINT"
// Сброс всех пунктов
const RESET_ALL_POINT = "RESET_ALL_POINT"
// Удаление пункта из хранилища
const DELETE_POINT = "DELETE_POINT"

const UPDATE_POINT = "UPDATE_POINT"

export const selectReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_POINT:
            return {...state, list: [...state.list, {data: action.payload.pointData, num: action.payload.num}]};
        case DELETE_POINT:
            return {...state, list: state.list.filter(point => point.data.item.guid !== action.payload.item.guid)};
        case UPDATE_POINT:
            // eslint-disable-next-line
            state.list.map((itemPoint, index) => {
                if(itemPoint.data.item.guid === action.payload.data.item.guid) {
                    state.list[index].data = action.payload.data;
                    state.list[index].num = Number(action.payload.num);
                }
            });
            return state;
        case RESET_ALL_POINT:
            state.list = [];
            return state;
        default:
            return state;
    }
}

export const setSelectPointAction = (payload) => ({type: ADD_POINT, payload})
export const deleteSelectPointAction = (payload) => ({type: DELETE_POINT, payload})
export const updateSelectListPointAction = (payload) => ({type: UPDATE_POINT, payload})
export const resetSelectPointAction = (payload) => ({type: RESET_ALL_POINT, payload})
