const defaultState = {
    sender: false
}
// Добавление локации в store
const SET_SENDER = "SET_SENDER"
// Удаление локации из store
const RESET_SENDER = "RESET_SENDER"

export const senderReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SENDER:
            return {...state, sender: action.payload.status}
        case RESET_SENDER:
            state = false;
            return state;
        default:
            return state
    }
}

export const setSenderAction = (payload) => ({type: SET_SENDER, payload})
export const resetSenderAction = (payload) => ({type: RESET_SENDER, payload})
