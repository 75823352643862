import React from "react";

const PlanDocumentBlockInfo = (props) =>
{
    return (
        <div className="itemBlockDocument planItem">
            <div>
                <div className="titleDocument">{props.data.item.name}</div>
            </div>
            <div>
                <div>{props.data.quantity}</div>
                <div>{props.data.unit}</div>
            </div>
        </div>
    )
}
export default PlanDocumentBlockInfo;