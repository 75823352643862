import React from "react";
import {useSelector} from "react-redux";
import HeaderW from "../../widgets/header/HeaderW";
import MenuW from "../../widgets/menu/MenuW";
import FooterW from "../../widgets/footer/FooterW";
import {useParams} from "react-router-dom";
import ArchiveObjectBlock from "../../widgets/content/ArchiveObjectBlock";


const ArchiveObjectPage = () => {

    let params = useParams();
    // ID выбранной локации
    let idLocation = params.idLocation

    let titleLocation = ''
    // Функция сравнения дат
    const compareDates = (a, b) => {
        return new Date(a.date) - new Date(b.date);
    }
    /** Отфильтровать уникальные локации, за указанный период */
    const selectObject = (documents, idLocation) => {
        let outList = []
        documents.map(doc => {
            if(doc.slug === idLocation && doc.final === true) {
                outList.push(doc)
            }
        })

        outList.sort(compareDates);

        return outList;
    }

    const documentStore = useSelector(state => state.document.documents);

    let objectData = selectObject(documentStore, idLocation);

    return (<>
            <HeaderW filter={true} title={titleLocation}/>
            <div className="screenBlock">
                {objectData.map((document, key) =>
                    <ArchiveObjectBlock link="/archive/loc/" filter={idLocation} data={document} key={key}/>
                )}
                <div className="bottom_space_list"></div>
            </div>
            <FooterW/>
            <MenuW screen="3"/>
        </>
    )

}

export default ArchiveObjectPage;
