const defaultState = false
// Добавление локации в store
const SET_OPEN = "SET_OPEN"
// Удаление локации из store
const RESET_OPEN = "RESET_OPEN"

export const openBlockReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_OPEN:
            return state = true;
        case RESET_OPEN:
            return state = false;
        default:
            return state
    }
}

export const setOpenAction = (payload) => ({type: SET_OPEN, payload})
export const resetOpenAction = (payload) => ({type: RESET_OPEN, payload})
