import React from "react";
import {NavLink} from "react-router-dom";
import home from "../../../assets/img/ios-home.svg";

const ArchiveObjectBlock = (props) => {
        return (
            <NavLink to={props.link + props.filter + '/document/' + props.data.id}
                     className="itemBlockObject archiveBlockObject">
                <p>
                <span>
                    <img src={home} alt=""/>
                </span>
                    Объект №{props.data.object} ({props.data.number})
                </p>
                <small>{props.data.doc_info.receiptDate.split(' ')[0]}</small>
            </NavLink>
        )
}
export default ArchiveObjectBlock;
