import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import HeaderW from "../../widgets/header/HeaderW";
import MenuW from "../../widgets/menu/MenuW";
import FooterW from "../../widgets/footer/FooterW";
import {useParams} from "react-router-dom";
import ObjectBlockNew from "../../widgets/content/ObjectBlockNew";
import {IndexedDB} from "../../../asinc/indexeddb";

const CurrentObjectPage = () => {
    const dispatch = useDispatch();
    let params = useParams();
    // ID выбранной локации
    let idLocation = params.idLocation
    // Данные о локациях текущих перемещений
    const documentStore = useSelector(state => state.document.documents);
    const [documentScreen, setDocumentScreen] = useState([]);
    // Переменная названия текущей локации
    let titleLocation = 'Текущие';

    /** Функция приведения типа даты 1С к формату JS */
    String.prototype.toDate = function (format) {
        let normalized = this.replace(/[^a-zA-Z0-9]/g, '-');
        let normalizedFormat = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
        let formatItems = normalizedFormat.split('-');
        let dateItems = normalized.split('-');

        let monthIndex = formatItems.indexOf("mm");
        let dayIndex = formatItems.indexOf("dd");
        let yearIndex = formatItems.indexOf("yyyy");
        let hourIndex = formatItems.indexOf("hh");
        let minutesIndex = formatItems.indexOf("ii");
        let secondsIndex = formatItems.indexOf("ss");

        let today = new Date();

        let year = yearIndex > -1 ? dateItems[yearIndex] : today.getFullYear();
        let month = monthIndex > -1 ? dateItems[monthIndex] - 1 : today.getMonth();
        let day = dayIndex > -1 ? dateItems[dayIndex] : today.getDate();

        let hour = hourIndex > -1 ? dateItems[hourIndex] : today.getHours();
        let minute = minutesIndex > -1 ? dateItems[minutesIndex] : today.getMinutes();
        let second = secondsIndex > -1 ? dateItems[secondsIndex] : today.getSeconds();

        return new Date(year, month, day, hour, minute, second);
    };

     // Функция сравнения дат
    const compareDates = (a, b) => {
        return new Date(a.date) - new Date(b.date);
    }

    const selectobject = (documents, idLocation) => {
        /** Отфильтровать уникальные локации, за указанный период */
        // Получаем текущую дату и обнуляем время
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        // Создаем дату для следующего дня
        let nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 3);
        let outList = [];
        documents.map(docState => {
            // Парсим дату из объекта и обнуляем время
            let objectDate = new Date(docState.date);
            objectDate.setHours(0, 0, 0, 0);
            // Проверяем, попадает ли дата объекта в допустимый интервал и его статус проведения
            if ( objectDate <= nextDay && !docState.final && idLocation ===  docState.slug) {
                outList.push(docState)
            }
        })

        // Сортировка массива по возрастанию даты
        outList.sort(compareDates);

        setDocumentScreen(outList);

        return outList;
    }

    documentStore.map(currentLocation => {
        if (idLocation === currentLocation.slug) {
            titleLocation = currentLocation.location;
        }
    })

    useEffect(() => {
        setTimeout(function () {
            selectobject(documentStore, idLocation);
        }, 100);
    }, [documentStore, dispatch])

    // Отправим на отрисовку с заданным статусом
    return (
        <>
            <HeaderW filter={true} title={titleLocation}/>
            <div className="hr_line"></div>
            <div className="screenBlock">
                {documentScreen.map((document, key) => (
                    <ObjectBlockNew link="/loc/" filter={idLocation} data={document} key={key}/>
                ))}
                <div className="bottom_space_list"></div>
            </div>
            <FooterW/>
            <MenuW screen="1"/>
        </>
    );

}

export default CurrentObjectPage;
