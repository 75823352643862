const defaultState = {
    work: []
}
// Добавление рабочего документа в store
const ADD_WORK_LIST = "ADD_WORK_LIST"
// Удаление рабочего документа из store
const UPDATE_WORK_LIST = "UPDATE_WORK_LIST"

const REMOVE_WORK_LIST = "REMOVE_WORK_LIST"

export const workListReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_WORK_LIST:
            // Обновим позицию в store по guid
            let guid = action.payload.guid

            let newOrderItems = []

            state.work.orderItems.map(itemData => {

                if (itemData.item.guid === guid)
                {
                    newOrderItems.push({
                        item: {
                            guid: itemData.item.guid,
                            name: itemData.item.name,
                            keyLine: itemData.item.keyLine,
                        },
                        quantity: Number(action.payload.num),
                        unit: itemData.unit,
                        quantitySupply: itemData.quantitySupply,
                    })
                } else {
                    newOrderItems.push(itemData)
                }
            });

            let newDocument = {
                guid: state.work.guid,
                number: state.work.number,
                receiptDate: state.work.receiptDate,
                orderItems: newOrderItems,
                constructionObject: state.work.constructionObject,
                analytic: state.work.analytic,
            }

            return {...state, work: newDocument}
        case ADD_WORK_LIST:
            return {...state, work: action.payload}
        case REMOVE_WORK_LIST:
            return {...state, work: {}}
        default:
            return state
    }
}

export const addWorkListReducerAction = (payload) => ({type: ADD_WORK_LIST, payload})
export const updateWorkListReducerAction = (payload) => ({type: UPDATE_WORK_LIST, payload})
export const removeWorkListReducerAction = (payload) => ({type: REMOVE_WORK_LIST, payload})
