import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import HeaderW from "../../widgets/header/HeaderW";
import MenuW from "../../widgets/menu/MenuW";
import {useParams} from "react-router-dom";
import FooterW from "../../widgets/footer/FooterW";
import DocumentBlock from "../../widgets/content/DocumentBlock";
import {addTakenToWorkAction, removeTakenToWorkAction} from "../../../store/takenToWorkReducer";
import {IndexedDB} from "../../../asinc/indexeddb";
import {send_log_data} from "../../../asinc/send_log_data";
import sendGif from '../../../assets/img/file-transfer.gif';
import {updateDocumentAction} from "../../../store/documentReducer";
import {deSendReq} from "../../../asinc/deSendReq";

const CurrentDocumentPage = () => {
    /** Экран отображения материалов перечисленных в документах */
    const dispatch = useDispatch();

    const btnRef = useRef(null)
    //
    const documentStore = useSelector(state => state.document.documents);
    const documentSendData = useSelector(state => state.work.work);
    const authorizationData = useSelector(state => state.authorizationReducer.authorization);
    const [update, setUpdate] = useState(false);
    const [animate, setAnimate] = useState(false);
    const [documentInfo, setDocumentInfo] = useState({})

    let params = useParams();
    // ID документа
    let idDocument = params.idDocument
    let idLocation = params.idLocation

    useLayoutEffect(
        () => {
            // Получим все документы и отфильтруем нужный с которым сейчас ведём работу
            let workDocument = documentStore.filter(function (itemDocument) {
                return (itemDocument.id === idDocument);
            })
            // Запишем документ в стор, для последующей работы с ним
            dispatch(removeTakenToWorkAction()) // Чистим хранилище перед записью
            dispatch(addTakenToWorkAction(workDocument[0]))
            setDocumentInfo(workDocument[0])
        }, []
    )

    useEffect(() => {
        setUpdate(true)
    }, []);

    const fetchSendDocument1CData = () => {
        setAnimate(true)

        let newMaterialOrder = [];

        // Исключим не принятые товары
        documentSendData.order.map(material => {
            // todo: Странное правило, разобраться в чём дело ( && material.quantity !== material.quantitySupply )
            if (material.quantity > 0) {
                newMaterialOrder.push(material)
            }
        })

        /** Сформируем объект для отправки на сервер 1С */
        let documentSending = {
            guid: documentSendData.doc_info.guid,
            number: documentSendData.number,
            receiptDate: documentSendData.doc_info.receiptDate,
            orderItems: newMaterialOrder,
            constructionObject: documentSendData.doc_info.constructionObject,
            analytic: documentSendData.doc_info.analytic
        }

        // Сменим статус документа, на отправленный
        dispatch(updateDocumentAction({
            guid: documentSendData.id,
            section: 'send',
            data: true,
            document: documentSending
        }));

        IndexedDB().then(db => {
            // Запустим транзакцию базы данных и получите хранилище объектов online_sends
            let tx = db.transaction(['online_sends'], 'readwrite');
            let store = tx.objectStore('online_sends');
            // Добавим готовый к отправке документ в хранилище
            store.add(documentSending);
            // Ожидаем завершения транзакции базы данных
            tx.oncomplete = () => {
                // Формируем уведомление о смене статуса документа на отправленный
                setTimeout(() => {
                    setAnimate(false);
                    window.history.back();
                }, 1000);
            }
            tx.onerror = (event) => {
                console.log('Ошибка записи документа на отправку' + event.target.errorCode);
                setTimeout(() => {
                    setAnimate(false);
                    window.history.back();
                }, 1000);
            }
        });
    }

        if (update) {
            if (documentSendData !== undefined && documentSendData !== []) {
                if (documentSendData.order) {
                    return (
                        <>
                            <HeaderW filter={true} title={documentInfo.doc_info.constructionObject}/>

                            <div className="screenBlock">
                                {documentSendData.order.map((material, key) =>
                                    <DocumentBlock send={documentSendData.send} data={material} key={key}/>
                                )}

                                <div
                                    ref={btnRef}
                                    className="btn sendBtn btn-block btn-lg"
                                    onClick={() => fetchSendDocument1CData()}
                                >
                                    Отправить
                                </div>
                                <div className="bottom_space_list"></div>

                            </div>
                            {animate &&
                                <img src={sendGif} alt="" className="sendAnimate"/>
                            }
                            <FooterW/>
                            <MenuW screen="1"/>
                        </>
                    )
                } else {
                    return (
                        <>
                            <HeaderW filter={true} title={"Текущие"}/>

                            <div className="screenBlock">
                                Нет элементов для отображения
                            </div>

                            <FooterW/>
                            <MenuW screen="1"/>
                        </>
                    )
                }
            } else {
                return (
                    <>
                        <HeaderW filter={true} title={"Текущие"}/>

                        <div className="screenBlock">
                            Нет элементов для отображения
                        </div>

                        <FooterW/>
                        <MenuW screen="1"/>
                    </>
                )
            }
        }
    }

    export default CurrentDocumentPage;
