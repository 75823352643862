import React from "react";
import {NavLink} from "react-router-dom";
import { slugify } from 'transliteration';
import location from "../../../assets/img/md-pin.svg";

const LocationBlock = (props) => {

    return (
        <NavLink to={props.link + slugify(props.data.location)} className="itemBlockLoacation">
            <p>
                <span>
                    <img src={location} alt=""/>
                </span>
                {props.data.location}
            </p>
        </NavLink>
    )
}
export default LocationBlock;
