import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import HeaderW from "../../widgets/header/HeaderW";
import MenuW from "../../widgets/menu/MenuW";
import FooterW from "../../widgets/footer/FooterW";
import {useParams} from "react-router-dom";
import ObjectBlock from "../../widgets/content/ObjectBlock";

const PlanObjectPage = () => {
    let params = useParams();
    // ID выбранной локации
    let idLocation = params.idLocation
    const dispatch = useDispatch();
    const documentStore = useSelector(state => state.document.documents);
    const [documentScreen, setDocumentScreen] = useState([]);
    const [updateScreen, setUpdateScreen] = useState(false);

    let titleLocation = ''

    // Функция сравнения дат
    const compareDates = (a, b) => {
        return new Date(a.date) - new Date(b.date);
    }
    const selectLocation = (documents) => {
        /** Отфильтровать уникальные локации, за указанный период */
            // Получаем текущую дату и обнуляем время
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        // Создаем дату для следующего дня
        let nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 3);
        let outList = []
        documents.map(docState => {
            if(idLocation === docState.slug) {
                if(titleLocation === '') {
                    titleLocation = docState.location;
                }
                // Парсим дату из объекта и обнуляем время
                let objectDate = new Date(docState.date);
                objectDate.setHours(0, 0, 0, 0);
                // Проверяем, попадает ли дата объекта в допустимый интервал и его статус проведения
                console.log('objectDate: ', objectDate)
                console.log('nextDay: ', nextDay)
                console.log('docState.final: ', docState.final)
                console.log('objectDate >= nextDay && docState.final === false: ', (objectDate >= nextDay && docState.final === false))
                if (objectDate > nextDay && docState.final === false) {
                    outList.push(docState)
                }
            }
        })

        // Сортировка массива по возрастанию даты
        outList.sort(compareDates);

        setDocumentScreen(outList);

        return outList;
    }

    useEffect(() => {
        setTimeout(function () {
            selectLocation(documentStore);
        }, 100);
    }, [documentStore, dispatch])


    useEffect(() => {selectLocation(documentStore)}, [updateScreen])

    return (<>
            <HeaderW filter={true} title={titleLocation}/>
            <div className="screenBlock">
                {documentScreen.map((document, key) =>
                    <ObjectBlock link="/plan/loc/" filter={idLocation} data={document} key={key}/>
                )}
                <div className="bottom_space_list"></div>
            </div>
            <FooterW/>
            <MenuW screen="2"/>
        </>
    )


}

export default PlanObjectPage;
