import React, {useEffect, useState} from "react";
import Point from "../../page/warehouses/Point";
import {useSelector} from "react-redux";
import upIcon from "../../../assets/img/chevron-up.svg";
import downIcon from "../../../assets/img/chevron-down.svg";

const AccordionBlock = (props) => {
    let dataBlock = props.data;
    let block = props.block;

    const [open, setOpen] = useState(false);
    const editWarehouses = useSelector(state => state.editWarehouses.status);
    const openStatus = useSelector(state => state.openBlock);

    const openAkkardeon = () => {
        // Если глобально не открыты карточки они сами за себя
        setOpen(!open)
    }

    useEffect(() => {
        setOpen(openStatus)
    }, [openStatus]);

    return (
        <div className="itemBlockStage">
            <h3 onClick={() => openAkkardeon()}>{dataBlock[block][0].analytic} <img src={open ? upIcon : downIcon} alt="" /></h3>
            <div className={open ? "akkardeon open" : "akkardeon"}>
                {dataBlock[block] && dataBlock[block].map((itemData, index) =>
                    <Point key={index} data={itemData} edit={editWarehouses}/>
                )}
            </div>
        </div>
    )
}
export default AccordionBlock;
