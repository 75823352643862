import React from "react";
import HeaderW from "../../widgets/header/HeaderW";
const ExitPage = () => {
    return (
        <>
            <HeaderW filter={false} title="Выход из приложения"/>
        </>
    )
}

export default ExitPage;