import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    deleteSelectPointAction,
    setSelectPointAction,
    updateSelectListPointAction,
} from "../../../store/selectReducer";


const Point = (props) => {

    const dispatch = useDispatch();
    const [editBlock, setEditBlock] = useState(false);
    const [numPoint, setNumPoint] = useState(props.data.quantity);
    const maxQuantity = props.data.quantity;


    useLayoutEffect(() => {
        setNumPoint(props.data.quantity);
        // eslint-disable-next-line
    }, []);
    // eslint-disable-next-line
    useEffect(() => {
        let pointData = props.data;
        pointData.quantity = Number(numPoint);
        let dataUpdate = {
            data: pointData,
            num: Number(numPoint)
        }
        dispatch(updateSelectListPointAction(dataUpdate))
        // eslint-disable-next-line
    }, [props.edit])

    const savePoint = (e) => {
        if(Number(e.target.value) <= Number(maxQuantity)) {
            setNumPoint(e.target.value)
        } else {
            setNumPoint(maxQuantity);
            window.alert('Ошибка! Значение не может быть больше чем: ' + maxQuantity);
        }
    }

    const setEditPoint = (pointData, num) =>
    {
        if(!editBlock) {
            // Добавим в хранилище пунктов на отправку
            dispatch(setSelectPointAction({pointData, num}))
        } else {
            // Удалим их хранилища
            dispatch(deleteSelectPointAction(pointData))
        }

        setEditBlock(!editBlock);
    }

    if(!props.edit) {
        return (
            <div className={editBlock ? "pointStage selectBlock" : "pointStage"} onClick={(e) => setEditPoint(props.data, numPoint)}>
                <div><input type="checkbox" checked={editBlock}/></div>
                <div className="name">{props.data.item.name}, {props.data.item.unit}</div>
                <div className="quantity">{numPoint}</div>
            </div>
        )
    } else {
        return (
            <div className="pointStage editPointBG" >
                <div className="name">{props.data.item.name}, {props.data.item.unit}</div>
                <div className="quantity">
                    <input type="number" value={numPoint} max={maxQuantity} onChange={(e) => savePoint(e)} />
                </div>
            </div>
        )
    }
}

export default Point;
