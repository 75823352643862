import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import HeaderW from "../../widgets/header/HeaderW";
import MenuW from "../../widgets/menu/MenuW";
import {useParams} from "react-router-dom";
import FooterW from "../../widgets/footer/FooterW";
import DocumentBlockInfo from "../../widgets/content/DocumentBlockInfo";
import {addTakenToWorkAction} from "../../../store/takenToWorkReducer";

const ArchiveDocumentPage = () => {
    /** Экран отображения материалов перечисленных в документах */
    const dispatch = useDispatch();
    //
    const documentStore = useSelector(state => state.document.documents);

    const [documentInfo, setDocumentInfo] = useState({})

    let params = useParams();
    // ID документа
    let idDocument = params.idDocument

    useLayoutEffect(
        () => {
            // Получим все документы и отфильтруем нужный с которым сейчас ведём работу
            let workDocument = documentStore.filter(function (itemDocument) {
                return (itemDocument.id === idDocument);
            })
            // Запишем документ в стор, для последующей работы с ним
            dispatch(addTakenToWorkAction(workDocument[0]))
            setDocumentInfo(workDocument[0])
        }, []
    )

    if (documentInfo.order) {
        return (
            <>
                <HeaderW filter={true} title={documentInfo.location + ' Объект №' + documentInfo.object }/>
                <div className="screenBlock">
                    {documentInfo.order.map((material, key) =>
                        <DocumentBlockInfo data={material} key={key}/>
                    )}
                    <div className="bottom_space_list"></div>
                </div>
                <FooterW/>
                <MenuW screen="3"/>
            </>
        )
    } else {
        return (
            <>
                <HeaderW filter={true} title={"Архивные"}/>

                <div className="screenBlock">
                    Нет элементов для отображения
                </div>

                <FooterW/>
                <MenuW screen="3"/>
            </>
        )
    }


}

export default ArchiveDocumentPage;
