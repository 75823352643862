const defaultState = {
    filter: {
        active: false,
    }
}
// Добавление локации в store
const SET_FILTER = "SET_FILTER"
// Удаление локации из store
const RESET_FILTER = "RESET_FILTER"

export const filterReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_FILTER:
            console.log(state.filter.active)
            let newState = !state.filter.active
            console.log(newState)
            return {...state, filter: {
                    active: newState
                }}
        case RESET_FILTER:
            return {...state, filter: {
                    active: false
                }}
        default:
            return state
    }
}

export const setFilterAction = (payload) => ({type: SET_FILTER, payload})
export const resetFilterAction = (payload) => ({type: RESET_FILTER, payload})