const defaultState = {
    screen: {
        number: '1',
        depth: 1
    }
}
// Добавление локации в store
const SET_SCREEN = "SET_SCREEN"
// Удаление локации из store
const RESET_SCREEN = "RESET_SCREEN"
// Установка глубины погружения
const SET_DEPTH = "SET_DEPTH"

export const screenReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SCREEN:
            return {...state, screen: {
                    number: action.payload.number,
                    depth: action.payload.depth,
                }}
        case RESET_SCREEN:
            return {...state, screen: {
                    number: '1',
                    depth: 1
                }}
        case SET_DEPTH:
            return {...state, screen: {
                    number: state.screen.number,
                    depth: action.payload.depth,
                }}
        default:
            return state
    }
}

export const setScreenAction = (payload) => ({type: SET_SCREEN, payload})
export const resetScreenAction = (payload) => ({type: RESET_SCREEN, payload})
export const setDepthAction = (payload) => ({type: SET_DEPTH, payload})