import React, {useLayoutEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {updateTakenToWorkAction} from "../../../store/takenToWorkReducer";
import {updateDocumentAction} from "../../../store/documentReducer";
import save from "../../../assets/img/save-pr.svg";
const DocumentBlock = (props) => {

    const [edit, setEdit] = useState(false);
    // Если документ в состоянии отправки, то учитываем установленное значение в расчёте лимита
    let limit_count = Number(props.data.quantity);

    const [editState, setEditState] = useState(false);

    const dispatch = useDispatch();

    const [num, setNum] = useState(limit_count);

    const maxQuantity = limit_count;

    const editAction = (guid, keyLine, num) => {
        if (edit) {
            let newNum = Number(num).toFixed(2);
            dispatch(updateTakenToWorkAction({guid, newNum}))
            dispatch(updateDocumentAction({
                section: 'order',
                keyLine: keyLine,
                guid: guid,
                num: Number(newNum)
            }))
        }
        setEdit(!edit)
    }

    useLayoutEffect(() => {

        let n_num2 = Number(limit_count).toFixed(2);

        dispatch(updateDocumentAction({
            section: 'order',
            keyLine: props.data.item.keyLine,
            guid: props.data.item.guid,
            num: n_num2
        }))
    }, [])

    const editInput = (e) => {

        if(Number(e.target.value) <= Number(maxQuantity))
        {
            setNum(e.target.value)
        } else {
            setNum(maxQuantity);
            alert('Значение больше указанного в лимитке! Значение не должно быть больше ' + maxQuantity)
        }
    }


    // Если позиция принята, то она отображается серой
    if (props.data.total === props.data.quantitySupply) {
        return (
            <div className={"itemBlockDocument acceptedItem"}>
                <div>
                    <div className="titleDocument">{props.data.item.name}</div>
                </div>
                <div>
                    <div>{props.data.quantity}</div>
                    <div>{props.data.unit}</div>
                </div>
            </div>
        )
    } else {
        // Если принята не полностью, то подсвечивается розовым цветом
        if (edit) {
            return (
                <div className="itemBlockDocument">
                    <div>
                        <div className="titleDocument"
                             onClick={() => editAction(props.data.item.guid, props.data.item.keyLine, num)}>
                            <img src={save} alt=""/>
                            <p>&ensp;{props.data.item.name}</p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <input
                                onChange={(e) => editInput(e)}
                                className="editInput"
                                type="number"
                                max={maxQuantity}
                                value={num}/>
                        </div>
                        <div>{props.data.unit}</div>
                    </div>
                </div>
            )
        } else {

            let editeBle = ""

            if (editState) {
                editeBle = "editItem"
            } else {
                editeBle = ""
            }

            let classSupply = ''

            if (props.data.quantitySupply > 0) {
                classSupply = 'supplyQuantity'
            }

            return (
                <div className={"itemBlockDocument " + editeBle + ' ' + classSupply}>
                    <div>
                        <div className="titleDocument">{props.data.item.name}</div>
                    </div>
                    <div>
                        <div onClick={() => editAction(props.data.item.guid)}>{num}</div>
                        <div>{props.data.unit}</div>
                    </div>
                </div>
            )
        }

    }
}
export default DocumentBlock;
